/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight, radius, spacing } from '../../theme';

const variants: {
  [key: string]: {
    background: ((arg: any) => any) | string;
    borderColor: ((arg: any) => any) | string;
    handleColor: ((arg: any) => any) | string;
    disabledBackground: ((arg: any) => any) | string;
    disabledBorderColor: ((arg: any) => any) | string;
    disabledHandleColor: ((arg: any) => any) | string;
    checkedBackground: ((arg: any) => any) | string;
    checkedBorderColor: ((arg: any) => any) | string;
    checkedHandleColor: ((arg: any) => any) | string;
    checkedHoverHandleColor: ((arg: any) => any) | string;
    disabledCheckedBackground: ((arg: any) => any) | string;
    disabledCheckedBorderColor: ((arg: any) => any) | string;
    disabledCheckedHandleColor: ((arg: any) => any) | string;
    labelColor: ((arg: any) => any) | string;
    disabledLabelColor: ((arg: any) => any) | string;
    focusBackground: ((arg: any) => any) | string;
    checkedFocusBackground: ((arg: any) => any) | string;
  };
} = {
  inverse: {
    background: color('black'),
    borderColor: color('midnight_800'),
    handleColor: color('midnight_800'),
    disabledBackground: color('midnight_900'),
    disabledBorderColor: color('midnight_850'),
    disabledHandleColor: color('midnight_850'),
    checkedBackground: color('midnight_200'),
    checkedBorderColor: color('midnight_800'),
    checkedHandleColor: color('midnight_800'),
    checkedHoverHandleColor: color('midnight_700'),
    disabledCheckedBackground: color('midnight_900'),
    disabledCheckedBorderColor: color('midnight_850'),
    disabledCheckedHandleColor: color('midnight_850'),
    labelColor: color('text_default_inverse'),
    disabledLabelColor: color('text_disabled_inverse'),
    focusBackground: color('midnight_850'),
    checkedFocusBackground: color('midnight_200')
  }
};

export const StyledSwitcher = styled.span<{
  variant?: string;
}>`
  display: inline-flex;
  align-items: center;

  input {
    appearance: none;
    height: ${spacing('xl')};
    width: 3.5rem;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 2px solid ${color('midnight_200')};
    background: ${color('midnight_150')};
    border-radius: ${radius('full')};
    transition: 0.3s;
    flex: 1 0 auto;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 3px;
      top: 4px;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      background: ${color('midnight_200')};
      transform: translateX(0);
      transition: 0.3s;
    }
    &:checked {
      background: ${color('midnight_800')};
      border-color: ${color('midnight_800')};

      &::after {
        background: ${color('midnight_200')};
        transform: translateX(24px);
      }

      &:hover {
        background: ${color('midnight_600')};
        border-color: ${color('midnight_600')};

        &::after {
          background: ${color('midnight_200')};
        }
      }
    }

    &:hover {
      border-color: ${color('midnight_400')};
      &::after {
        background: ${color('midnight_400')};
      }
    }

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 4px ${color('midnight_150')};
      background-color: ${color('midnight_150')};

      &:checked {
        background: ${color('midnight_800')};
        border-color: ${color('midnight_800')};
      }
    }

    &[disabled] {
      pointer-events: none;
      background-color: ${color('midnight_100')};
      border-color: ${color('midnight_150')};

      &::after {
        background-color: ${color('midnight_150')};
      }

      + label {
        color: ${color('text_disabled')};
      }

      &:checked {
        border-color: ${color('midnight_100')};
      }
    }
  }

  ${({ variant }) =>
    variant &&
    variants[variant] &&
    css`
      input {
        background-color: ${variants[variant].background};
        border-color: ${variants[variant].borderColor};

        &::after {
          background: ${variants[variant].handleColor};
        }

        &:checked {
          background-color: ${variants[variant].checkedBackground};
          border-color: ${variants[variant].checkedBorderColor};

          &::after {
            background: ${variants[variant].checkedHandleColor};
          }
        }

        &[disabled] {
          background-color: ${variants[variant].disabledBackground};
          border-color: ${variants[variant].disabledBorderColor};

          &::after {
            background-color: ${variants[variant].disabledHandleColor};
          }

          + label {
            color: ${variants[variant].disabledLabelColor};
          }

          &:checked {
            background-color: ${variants[variant].disabledCheckedBackground};
            border-color: ${variants[variant].disabledCheckedBorderColor};

            &::after {
              background-color: ${variants[variant].disabledCheckedHandleColor};
            }
          }
        }

        &:focus {
          background-color: ${variants[variant].focusBackground};

          &:checked {
            background-color: ${variants[variant].checkedFocusBackground};

            &:hover::after {
              background-color: ${variants[variant].checkedHoverHandleColor};
            }
          }
        }
      }
      label {
        color: ${variants[variant].labelColor};
      }
    `}

  label {
    margin-bottom: 0;
    margin-left: ${spacing('xs')};
    font-size: ${fontSize('sm')};
    font-weight: ${fontWeight('medium')};
    flex: 1 1 auto;
  }
`;
