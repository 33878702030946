import styled, { css } from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  shadow,
  spacing
} from '../../theme';

export const StyledCheckboxIcon = styled.div`
  flex-shrink: 0;
  width: ${spacing('lg')};
  height: ${spacing('lg')};
  border-radius: ${radius('sm')};
  background-color: ${color('white')};
  border: 2px solid ${color('midnight_200')};
  margin-right: ${spacing('xs')};
  position: relative;
  transition: 0.3s;

  &::after {
    content: '';
    transform: translate(-50%, -50%) rotate(-45deg);
    outline: 1px solid transparent;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 0.8em;
    height: 0.4em;
    border: 3px solid ${color('midnight_200')};
    border-top: none;
    border-right: none;
    border-radius: 2px;
    background-color: transparent;
    opacity: 0;
    transition: 0.3s;
  }
`;

export const StyledCheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${spacing('lg')};
`;

export const StyledCheckbox = styled.div<{ isCard: boolean }>`
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    &:checked + label ${StyledCheckboxIcon} {
      background-color: ${color('midnight_800')};
      border-color: ${color('midnight_800')};

      &::after {
        opacity: 1;
      }
    }

    &:hover + label ${StyledCheckboxIcon} {
      border-color: ${color('midnight_400')};
    }

    &:checked:hover + label ${StyledCheckboxIcon} {
      border-color: ${color('midnight_600')};
      background-color: ${color('midnight_600')};
    }

    &:focus + label ${StyledCheckboxIcon} {
      outline: 0;
      border-color: ${color('midnight_850')};
      box-shadow: 0 0 0 4px ${color('midnight_150')};
    }

    &[disabled] {
      pointer-events: none;

      & + label {
        color: ${color('text_disabled')};
        pointer-events: none;

        ${StyledCheckboxIcon} {
          border-color: ${color('text-disabled')};
          background-color: ${color('midnight_150')};
        }
      }

      &:checked + label ${StyledCheckboxIcon} {
        border-color: ${color('midnight_150')};
        background-color: ${color('midnight_150')};
      }
    }
  }

  ${({ isCard }) =>
    isCard &&
    css`
      input[type='checkbox'] {
        & + label {
          border: 1px solid ${color('midnight_200')};
          border-radius: ${radius('md')};
          padding: ${spacing('md')};
          box-shadow: ${shadow('md')};
          cursor: pointer;

          font-weight: ${fontWeight('extraBold')};
        }

        &:checked + label {
          background: ${color('active')};
          border-color: ${color('active')};
        }
      }
    `}
`;

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: ${fontSize('sm')};
  line-height: 1;
  vertical-align: text-bottom;
`;
