import { faExternalLinkAlt, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faClone, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/shared/Avatar';
import Button from 'components/shared/Button.styled';
import ButtonLink from 'components/shared/ButtonLink.styled';
import Dropdown from 'components/shared/Dropdown';
import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { SideSubNav } from 'features/portal/v2/components/layout/portalSidebar/SideSubNav';
import { useI18n } from 'hooks/useI18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import ProfilePreviewModal from '../Modal/ManfrediteProfileModal';
import {
  StyledActions,
  StyledBeta,
  StyledBlank,
  StyledClose,
  StyledContent,
  StyledDropdown,
  StyledHeader,
  StyledLogo,
  StyledName,
  StyledNavItem,
  StyledSection,
  StyledSideNav,
  StyledTitle
} from './ManfrediteProfileNav.styled';
import { ManfrediteProfileNavProps } from './ManfrediteProfileNav.types';

const ManfrediteProfileNav: React.FC<ManfrediteProfileNavProps> = ({
  sections,
  publicManfredite,
  isActive,
  setIsActive,
  isDropdownOpen,
  setIsDropdownOpen,
  setSelectedViewType,
  selectedViewType,
  handleSaveSuccessCopy,
  saveAsPdfURL,
  url,
  includeMailContactInfo,
  includePhoneContactInfo,
  setIncludeMailContactInfo,
  setIncludePhoneContactInfo,
  isModalOpen,
  setIsModalOpen
}) => {
  const { t } = useI18n('profile-preview');
  const { asPath } = useRouter();

  const { publicProfileActive } = useContext(LoggedUserContext);

  const { avatarUrl, avatarAlt, firstName, lastName } = publicManfredite;

  function toggleNav(): void {
    setIsActive(isActive === '' ? 'is-active' : '');
  }

  return (
    <>
      <ProfilePreviewModal
        includeMailContactInfo={includeMailContactInfo}
        includePhoneContactInfo={includePhoneContactInfo}
        setIncludeMailContactInfo={setIncludeMailContactInfo}
        setIncludePhoneContactInfo={setIncludePhoneContactInfo}
        isOpen={isModalOpen}
        saveAsPdfURL={saveAsPdfURL}
        close={() => {
          setIsModalOpen(false);
        }}
      />
      <StyledSideNav className={`${isActive}`}>
        <div>
          <StyledHeader>
            <StyledLogo>
              {avatarUrl && (
                <Avatar
                  avatarImg={avatarUrl}
                  avatarAlt={avatarAlt || ''}
                  sizes="md"
                />
              )}

              {(firstName || lastName) && (
                <StyledName>
                  {firstName || ''} {lastName || ''}
                </StyledName>
              )}
            </StyledLogo>
            <StyledClose onClick={toggleNav}>
              <FontAwesomeIcon icon={faTimes} />
            </StyledClose>
          </StyledHeader>
          <StyledActions>
            <StyledDropdown>
              <Button
                variant="secondaryInverse"
                sizes="sm"
                isTrigger
                onClick={() => {
                  setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
                }}
                aria-haspopup="listbox"
                aria-controls="cvView-dropdown"
                aria-label="cvView"
                aria-expanded={isDropdownOpen ? 'true' : 'false'}
              >
                <span>
                  {selectedViewType === 'classic'
                    ? t('profile-preview.topbar.views.classic')
                    : t('profile-preview.topbar.views.timeline')}
                </span>
              </Button>

              <Dropdown
                isAlignLeft
                options={[
                  {
                    label: t('profile-preview.topbar.views.timeline'),
                    value: 'timeline',
                    action: () => {
                      setIsDropdownOpen(false);
                      setSelectedViewType('timeline');
                    }
                  },
                  {
                    label: t('profile-preview.topbar.views.classic'),
                    value: 'classic',
                    action: () => {
                      setIsDropdownOpen(false);
                      setSelectedViewType('classic');
                    }
                  }
                ]}
                style={{ display: isDropdownOpen ? 'block' : 'none' }}
              />
            </StyledDropdown>

            <ButtonLink
              variant="tertiaryInverse"
              sizes="sm"
              onClick={() => {
                setIncludeMailContactInfo(true);
                setIncludePhoneContactInfo(true);
                setIsModalOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faCloudArrowDown} />
              <span>{t('profile-preview.topbar.pdf-download')}</span>
            </ButtonLink>
            {publicProfileActive && (
              <Button
                variant="tertiaryInverse"
                onClick={() => {
                  handleSaveSuccessCopy(url);
                }}
                sizes="sm"
              >
                <FontAwesomeIcon icon={faClone} />
                <span>{t('profile-preview.topbar.copy-link')}</span>
              </Button>
            )}
          </StyledActions>

          <StyledContent>
            {sections.map((section, index) => (
              <StyledSection key={index}>
                <StyledTitle>
                  {t(section.title, { ns: 'portal-layout' })}
                </StyledTitle>
                {section.navItems.map((navItem, index) => (
                  <div key={index}>
                    {!navItem.subNavItems && navItem.href && navItem.icon && (
                      <Link href={navItem.href} passHref>
                        <StyledNavItem
                          active={navItem.href === asPath}
                          isHighlighted={navItem.isHighlighted}
                          target={navItem.blank ? '_blank' : undefined}
                        >
                          <FontAwesomeIcon icon={navItem.icon} />
                          <span>
                            {t(navItem.title, { ns: 'portal-layout' })}{' '}
                            {navItem.totalItems != undefined &&
                              navItem.totalItems > -1 && (
                                <strong>({navItem.totalItems})</strong>
                              )}
                          </span>

                          {navItem.isBeta && (
                            <StyledBeta>
                              {t('beta', { ns: 'common' })}
                            </StyledBeta>
                          )}

                          {navItem.blank && (
                            <StyledBlank>
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledBlank>
                          )}
                        </StyledNavItem>
                      </Link>
                    )}

                    {navItem.subNavItems && (
                      <SideSubNav
                        key={`nav_${index}`}
                        title={t(navItem.title, {
                          ns: 'portal-layout'
                        })}
                        subNavItems={navItem.subNavItems}
                        totalFields={navItem.totalFields}
                        completedFields={navItem.completedFields}
                        totalItems={navItem.totalItems}
                      />
                    )}
                  </div>
                ))}
              </StyledSection>
            ))}
          </StyledContent>
        </div>
      </StyledSideNav>
    </>
  );
};

export default ManfrediteProfileNav;
