import { ChangeEvent } from 'react';
import { StyledSwitcher } from './Switcher.styled';

export type SwitcherProps = {
  label?: string | null;
  id: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  variant?: string;
};

const Switcher = ({
  label,
  id,
  checked,
  onChange,
  disabled = false,
  className,
  defaultChecked,
  variant
}: SwitcherProps) => (
  <StyledSwitcher className={className} variant={variant}>
    <input
      type="checkbox"
      id={id}
      name={id}
      defaultChecked={defaultChecked}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />

    {label && <label htmlFor={id}>{label}</label>}
  </StyledSwitcher>
);

export default Switcher;
