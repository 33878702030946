import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faBallotCheck,
  faBell,
  faBriefcase,
  faCloudDownload,
  faCompass as faCompassLight,
  faEnvelopeOpenText,
  faGraduationCap,
  faIdCard,
  faLanguage,
  faLock,
  faMegaphone,
  faUserAstronaut
} from '@fortawesome/pro-light-svg-icons';
import {
  DEFAULT_FILTER,
  OPEN_ALERT_PARAM
} from 'features/job-offers/list/components/JobOffersListComponent.constants';
import { NavSection } from 'features/portal/v2/components/layout/portalSidebar';
import {
  PORTAL_SIDENAV_EVENT_NAV_CONTACT_INFORMATION,
  PORTAL_SIDENAV_EVENT_NAV_DOWNLOAD_PROFILE_PDF,
  PORTAL_SIDENAV_EVENT_NAV_EDUCATION_AND_KNOWLEDGE,
  PORTAL_SIDENAV_EVENT_NAV_IMPORT_FROM_LINKEDIN,
  PORTAL_SIDENAV_EVENT_NAV_JOB_OFFERS,
  PORTAL_SIDENAV_EVENT_NAV_JOB_SEARCH,
  PORTAL_SIDENAV_EVENT_NAV_LANGUAGES,
  PORTAL_SIDENAV_EVENT_NAV_MANAGE_ALERTS,
  PORTAL_SIDENAV_EVENT_NAV_PRESENTATION,
  PORTAL_SIDENAV_EVENT_NAV_PRIVACY_AND_ACCESS,
  PORTAL_SIDENAV_EVENT_NAV_PROFESSIONAL_EXPERIENCE,
  PORTAL_SIDENAV_EVENT_NAV_SALARY_COMPASS,
  PORTAL_SIDENAV_EVENT_NAV_TECH_ROLES_GLOSSARY,
  PORTAL_SIDENAV_EVENT_NAV_TRACK_JOB_APPLICATIONS
} from 'features/portal/v2/components/layout/portalSidebar/SideNav.constants';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getUrlParamsFromJobOffersFilter } from 'utils/job-offers-filter';
import { getLocale } from 'utils/localize.utils';

export const PROFILE_PREVIEW_NAV_V2 = (
  isLinkedInEnabled: boolean,
  locale?: string
): NavSection[] => {
  return [
    {
      title: 'portal-layout.sections.manfred-positions',
      navItems: [
        {
          title: 'portal-layout.items.offers',
          href: `${getAppUrl(
            APP_URLS.jobOffers,
            locale
          )}?${getUrlParamsFromJobOffersFilter(
            DEFAULT_FILTER,
            getLocale(locale)
          )}`,
          blank: true,
          icon: faMegaphone,
          onClick: () => {
            ServiceEventTracking.track(PORTAL_SIDENAV_EVENT_NAV_JOB_OFFERS);
          }
        },
        {
          title: 'portal-layout.items.job-offer-alerts-management',
          icon: faBell,
          href: `${getAppUrl(
            APP_URLS.jobOffers,
            locale
          )}?${getUrlParamsFromJobOffersFilter(
            DEFAULT_FILTER,
            getLocale(locale)
          )}&${OPEN_ALERT_PARAM}=true`,
          blank: true,
          onClick: () => {
            ServiceEventTracking.track(PORTAL_SIDENAV_EVENT_NAV_MANAGE_ALERTS);
          }
        },
        {
          title: 'portal-layout.items.job-hunt',
          icon: faBallotCheck,
          href: `${getAppUrl(APP_URLS.portalV2JobHunt, locale)}`,
          onClick: () => {
            ServiceEventTracking.track(PORTAL_SIDENAV_EVENT_NAV_JOB_SEARCH);
          }
        }
      ]
    },

    {
      title: 'portal-layout.sections.your-profile',
      navItems: [
        {
          title: 'portal-layout.items.contact',
          href: `${getAppUrl(APP_URLS.portalV2Contact, locale)}`,
          icon: faIdCard,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_CONTACT_INFORMATION
            );
          }
        },
        {
          title: 'portal-layout.items.introduction',
          href: `${getAppUrl(APP_URLS.portalV2Introduction, locale)}`,
          icon: faMegaphone,
          onClick: () => {
            ServiceEventTracking.track(PORTAL_SIDENAV_EVENT_NAV_PRESENTATION);
          }
        },
        {
          title: 'portal-layout.items.knowledgeAndEducation',
          href: `${getAppUrl(APP_URLS.portalV2KnowledgeAndEducation, locale)}`,
          icon: faGraduationCap,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_EDUCATION_AND_KNOWLEDGE
            );
          }
        },
        {
          title: 'portal-layout.items.languages',
          href: `${getAppUrl(APP_URLS.portalV2Languages, locale)}`,
          icon: faLanguage,
          onClick: () => {
            ServiceEventTracking.track(PORTAL_SIDENAV_EVENT_NAV_LANGUAGES);
          }
        },
        {
          title: 'portal-layout.items.experience',
          href: `${getAppUrl(APP_URLS.portalV2Experience, locale)}`,
          icon: faBriefcase,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_PROFESSIONAL_EXPERIENCE
            );
          }
        },
        {
          title: 'portal-layout.items.linkedin-import',
          href: `${getAppUrl(APP_URLS.portalV2Settings, locale)}#thirdParty`,
          icon: faLinkedin,
          isHighlighted: true,
          isHidden: !isLinkedInEnabled,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_IMPORT_FROM_LINKEDIN
            );
          }
        }
      ]
    },

    {
      title: 'portal-layout.sections.move-your-profile',
      navItems: [
        {
          title: 'portal-layout.items.download-profile',
          href: `${getAppUrl(APP_URLS.portalV2DownloadProfile, locale)}`,
          icon: faCloudDownload,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_DOWNLOAD_PROFILE_PDF
            );
          }
        },
        {
          title: 'portal-layout.items.privacy-settings',
          href: `${getAppUrl(APP_URLS.portalV2PrivacyAndProfile, locale)}`,
          icon: faLock,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_PRIVACY_AND_ACCESS
            );
          }
        },
        {
          title: 'portal-layout.items.external-applications',
          href: `${getAppUrl(APP_URLS.portalV2ThirdPartyApplications, locale)}`,
          icon: faEnvelopeOpenText,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_TRACK_JOB_APPLICATIONS
            );
          }
        }
      ]
    },

    {
      title: 'portal-layout.sections.orientation',
      navItems: [
        {
          title: 'portal-layout.items.salary-benchmark',
          href: `${getAppUrl(APP_URLS.portalV2SalaryBenchmark, locale)}`,
          icon: faCompassLight,
          onClick: () => {
            ServiceEventTracking.track(PORTAL_SIDENAV_EVENT_NAV_SALARY_COMPASS);
          }
        },
        {
          title: 'portal-layout.items.roles-guide',
          href: `${getAppUrl(APP_URLS.roles, locale)}`,
          blank: true,
          icon: faUserAstronaut,
          onClick: () => {
            ServiceEventTracking.track(
              PORTAL_SIDENAV_EVENT_NAV_TECH_ROLES_GLOSSARY
            );
          }
        }
      ]
    }
  ];
};
