import { Button } from 'components/shared/Button.styled';
import styled from 'styled-components';
import { color, fontSize, screen, spacing } from 'theme';

export const StyledActions = styled.div`
  @media (max-width: ${screen('mdMax')}) {
    display: none;
  }
`;

export const StyledTopbarRoot = styled.div`
  position: relative;
`;

export const StyledTopbarOverlay = styled.div`
  /* Positioning. */
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  /* Color */
  background-color: ${color('info_900')};
  opacity: 0.75;
`;

export const StyledTopbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${color('midnight_900')};
  padding-left: ${spacing('lg')};
  padding-right: ${spacing('lg')};
  min-height: 5rem;
`;

export const StyledTopbarSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing('xs')};
  color: ${color('text_default_inverse')};

  @media (max-width: ${screen('mdMax')}) {
    flex-grow: 1;
    justify-content: space-between;
  }
`;

export const StyledTopbarNavTrigger = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  padding-top: 0.35rem;
  font-size: ${fontSize('xl')};
  color: ${color('midnight_300')};
  width: ${spacing('lg')};
  margin-right: ${spacing('sm')};
`;

export const StyledDropdown = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${Button} {
    border-color: ${color('midnight_700')};
  }
`;
