import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonLink from 'components/shared/ButtonLink.styled';
import Checkbox from 'components/shared/Checkbox';
import Modal from 'components/shared/Modal';
import { useI18n } from 'hooks/useI18n';
import { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import ServiceEventTracking from 'services/service.event-tracking';
import {
  StyledActions,
  StyledChecks,
  StyledIcon,
  StyledText,
  StyledTitle,
  StyledWrapper
} from './ManfrediteProfileModal.styled';
import { ManfrediteProfileModalProps } from './ManfrediteProfileModal.types';

const ManfrediteProfileModal: React.FC<ManfrediteProfileModalProps> = ({
  isOpen,
  close,
  saveAsPdfURL,

  includeMailContactInfo,
  includePhoneContactInfo,
  setIncludeMailContactInfo,
  setIncludePhoneContactInfo
  //selectedViewType
}) => {
  const { t } = useI18n('profile-preview');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSubmitting(false);
    }
  }, [isOpen]);

  const handleIncludeMailContactInfo = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIncludeMailContactInfo(e.currentTarget.checked);
  };

  const handleIncludePhoneContactInfo = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setIncludePhoneContactInfo(e.currentTarget.checked);
  };

  const trackDownloadClick = useCallback((): void => {
    ServiceEventTracking.track({
      type: 'Profile - Profile Exported',
      properties: {
        result: 'unknown',
        fileFormat: 'pdf',
        service: 'none'
      }
    });
  }, []);

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        contentLabel={'label'}
        shouldCloseOnOverlayClick={true}
        onRequestClose={close}
      >
        <Modal onClose={close}>
          <StyledWrapper>
            <StyledIcon>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </StyledIcon>
            <StyledTitle>
              {t('profile-preview.modal-contact-info.title')}
            </StyledTitle>
            <StyledText>
              {t('profile-preview.modal-contact-info.subtitle')}
            </StyledText>
            <StyledChecks>
              <Checkbox
                id="contactInfoEmailActive"
                label={t('profile-preview.modal-contact-info.options.mail')}
                checked={includeMailContactInfo}
                onChange={handleIncludeMailContactInfo}
              />
              <Checkbox
                id="contactInfoPhoneActive"
                label={t('profile-preview.modal-contact-info.options.phone')}
                checked={includePhoneContactInfo}
                onChange={handleIncludePhoneContactInfo}
              />
            </StyledChecks>
            <StyledActions>
              <ButtonLink
                variant="primary"
                aria-label={
                  t('profile-preview.modal-contact-info.actions.add') || ''
                }
                onClick={() => {
                  trackDownloadClick();
                  close();
                }}
                href={saveAsPdfURL}
                target="_blank"
              >
                {!submitting && <FontAwesomeIcon icon={faCheckCircle} />}
                {submitting && <FontAwesomeIcon icon={faSpinnerThird} />}

                <span aria-hidden="true">
                  {t('profile-preview.modal-contact-info.actions.add')}
                </span>
              </ButtonLink>

              <ButtonLink
                variant="secondary"
                aria-label={
                  t('profile-preview.modal-contact-info.actions.ignore') || ''
                }
                onClick={() => {
                  close();
                  trackDownloadClick();
                  setIncludeMailContactInfo(false);
                  setIncludePhoneContactInfo(false);
                }}
                href={saveAsPdfURL}
                target="_blank"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
                <span aria-hidden="true">
                  {t('profile-preview.modal-contact-info.actions.ignore')}
                </span>
              </ButtonLink>
            </StyledActions>
          </StyledWrapper>
        </Modal>
      </ReactModal>
    </>
  );
};

export default ManfrediteProfileModal;
