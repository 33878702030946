import { forwardRef, ReactNode } from 'react';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledCheckboxLabel
} from './Checkbox.styled';
import { StyledHelpIcon } from './FormGroup.styled';
import TooltipMF from './TooltipMF';

export type CheckboxProps = Omit<
  React.HTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  label: ReactNode;
  helpContent?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  isCard?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, id, helpContent, isCard = false, ...rest }, ref) => {
    return (
      <StyledCheckbox isCard={isCard}>
        <input type="checkbox" id={id} {...rest} ref={ref} />

        <StyledCheckboxLabel htmlFor={id}>
          <StyledCheckboxIcon />
          <span>{label}</span>
        </StyledCheckboxLabel>

        {helpContent && (
          <TooltipMF content={helpContent} direction="down">
            <a href="#" onClick={(e) => e.preventDefault()}>
              <StyledHelpIcon />
            </a>
          </TooltipMF>
        )}
      </StyledCheckbox>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
