import styled, { css } from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  radius,
  spacing
} from '../../theme';

const roleVariants: {
  [key: string]: {
    borderColor?: string | ((key: any) => any);
  };
} = {
  error: {
    borderColor: color('negative_700')
  }
};

const sizeVariants: {
  [key: string]: {
    padding?: string | ((key: any) => string);
    fontSize?: string | ((key: any) => string);
    height?: string;
  };
} = {
  sm: {
    padding: '0.25rem 0.75rem',
    fontSize: fontSize('xs'),
    height: '2.5rem'
  }
};

export const StyledSelect = styled.select<{
  variant?: string;
  sizes?: string;
  disabled?: boolean;
}>`
  appearance: none;
  min-width: ${spacing('xl6')};
  height: 3.5rem;
  padding: 1rem 3.25rem 1rem 1.25rem;
  font-family: ${fontFamily('sans')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  color: ${color('text_default')};
  border-radius: ${radius('md')};
  border: 2px solid ${color('midnight_200')};
  transition: 0.3s;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.538289 0.730988H7.46171C7.94067 0.730988 8.18015 1.30951 7.84111 1.64855L4.38075 5.11161C4.17087 5.32149 3.82913 5.32149 3.61925 5.11161L0.158886 1.64855C-0.180154 1.30951 0.0593268 0.730988 0.538289 0.730988Z' fill='%23353241'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right ${spacing('lg')} top 50%;
  background-size: 0.65em auto;
  background-color: ${color('white')};

  ${({ variant }) =>
    variant &&
    roleVariants[variant] &&
    css`
      border-color: ${roleVariants[variant].borderColor} !important;
    `}

  ${({ sizes }) =>
    sizes &&
    sizeVariants[sizes] &&
    css`
      padding: ${sizeVariants[sizes].padding};
      font-size: ${sizeVariants[sizes].fontSize};
      height: ${sizeVariants[sizes].height};
    `}

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: ${color('midnight_400')};
  }

  &:focus {
    outline: 0;
    border-color: ${color('midnight_850')};
    box-shadow: 0 0 0 4px ${color('midnight_150')};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 1;
      pointer-events: none;
      background-color: ${color('midnight_150')};
      border-color: ${color('midnight_200')};
      color: ${color('text_disabled')};
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.538289 0.730957H7.46171C7.94067 0.730957 8.18015 1.30948 7.84111 1.64852L4.38075 5.11158C4.17087 5.32146 3.82913 5.32146 3.61925 5.11158L0.158886 1.64852C-0.180154 1.30948 0.0593268 0.730957 0.538289 0.730957Z' fill='%23DEDCE4'/%3E%3C/svg%3E%0A");
    `}
`;
