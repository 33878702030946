import { Button } from 'components/shared/Button.styled';
import { StyledDropdown as StyledDropdownMenu } from 'components/shared/Dropdown.styled';
import styled, { css } from 'styled-components';
import {
  color,
  container,
  fontSize,
  fontWeight,
  radius,
  screen,
  spacing,
  zindex
} from 'theme';

export const StyledSideNav = styled.div`
  background-color: ${color('midnight_800')};
  color: ${color('text_default_inverse')};
  height: 100vh;
  width: ${container('portalSidebar')};
  position: fixed !important;
  left: -${container('portalSidebar')};
  top: 0;
  z-index: ${zindex('portalSidebar')};
  transition: left 0.3s;

  @supports (-webkit-touch-callout: none) {
    height: auto;
  }

  &.is-active {
    left: 0 !important;
  }

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;

    /* Forces Safari mobile to not take into account the navbar */
    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
    }
  }
`;

export const StyledLogo = styled.div`
  flex-shrink: 0;
  margin-top: -3px;
  display: flex;
  align-items: center;

  @media (min-width: ${screen('lg')}) {
    margin-top: 0;
  }
`;

export const StyledName = styled.span`
  display: flex;
  align-items: center;
  padding-left: ${spacing('md')};
  width: 9rem;
`;

export const StyledHeader = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeight('extraBold')};
  display: flex;
  gap: ${spacing('md')};
  align-items: center;
  padding-top: ${spacing('lg')};
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};

  @media (min-width: ${screen('lg')}) {
    padding-top: ${spacing('xl')};
  }
`;

export const StyledClose = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  font-size: ${fontSize('xl2')};
  color: ${color('midnight_300')};
  width: ${spacing('lg')};
`;

export const StyledContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: ${spacing('lg')} ${spacing('md')};

  &::-webkit-scrollbar {
    height: 12px;
    width: ${spacing('xs2')};
    background: ${color('midnight_400')};
  }

  &::-webkit-scrollbar-thumb {
    background: ${color('midnight_600')};
  }

  &::-webkit-scrollbar-corner {
    background: ${color('midnight_800')};
  }
`;

export const StyledSection = styled.nav`
  padding-bottom: ${spacing('lg')};
`;

export const StyledTitle = styled.h6`
  margin-top: 0;
  margin-bottom: ${spacing('xs')};
  color: ${color('text_subtle_inverse')};
  font-size: ${fontSize('xs')};
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: ${fontWeight('extraBold')};
  padding-bottom: ${spacing('sm')};
  border-bottom: 1px solid ${color('midnight_700')};
`;

export const StyledNavItem = styled.a<{
  active?: boolean;
  isHighlighted?: boolean;
}>`
  -webkit-appearance: none;
  cursor: pointer;
  padding: ${spacing('xs')} 0;
  background: unset;
  border: unset;
  color: inherit;
  font-family: inherit;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing('xs')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
  }

  strong {
    font-weight: ${fontWeight('extraBold')};
  }

  &:hover {
    color: ${color('link_inverse_hover')};
  }

  &:active {
    color: ${color('link_inverse_active')};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${color('link_inverse_active')};
    `}
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      color: ${color('link_inverse')};
    `}
`;

export const StyledBeta = styled.span`
  color: ${color('notice_500')};
  font-size: ${fontSize('xs2')};
  font-weight: ${fontWeight('extraBold')};
  line-height: 1.2;
  text-transform: uppercase;
`;

export const StyledBlank = styled.span`
  color: ${color('midnight_500')};
  font-size: ${fontSize('xs2')};

  svg {
    height: ${fontSize('xs2')};
  }
`;

export const StyledProgress = styled.div<{ width?: number }>`
  background-color: ${color('midnight_700')};
  height: ${spacing('xs2')};
  border-radius: ${radius('sm')};
  margin-left: ${spacing('lg')};
  position: relative;
  transition: width 0.2s;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${color('emerald_500')};
    border-radius: ${radius('sm')};
    width: 0;
    height: ${spacing('xs2')};
    ${({ width }) =>
      width &&
      css`
        width: ${`${width}%`};
      `}
  }
`;

export const StyledSubNav = styled.div`
  padding-left: ${spacing('lg')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  &.is-active {
    margin-bottom: ${spacing('sm')};
  }

  ${StyledNavItem} {
    font-size: ${fontSize('xs')};
  }

  ${StyledProgress} {
    margin-left: 0;
    margin-bottom: ${spacing('xs')};
    max-width: 9.5rem;
  }
`;

export const StyledDropdown = styled.div`
  width: 100%;
  position: relative;

  ${Button} {
    background-color: ${color('black')};
    border-color: ${color('midnight_700')};
    width: 100%;
    text-align: left;
    justify-content: space-between;
  }

  ${StyledDropdownMenu} button {
    width: calc(${container('portalSidebar')} - ${spacing('lg')} * 2);
    min-width: unset;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing('xs2')};
  padding-top: ${spacing('lg')};
  padding-right: ${spacing('md')};
  padding-left: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    display: none;
  }
`;
