import { faBars } from '@fortawesome/pro-light-svg-icons';
import { faClone, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/shared/Button.styled';
import ButtonLink from 'components/shared/ButtonLink.styled';
import Dropdown from 'components/shared/Dropdown';
import HtmlTrans from 'components/shared/HtmlTrans';
import { LinkText } from 'components/shared/LinkText';
import Switcher from 'components/shared/Switcher';
import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { useManfredite, useManfrediteUpdate } from 'hooks/api/manfredite';
import { useI18n } from 'hooks/useI18n';
import { useToasts } from 'hooks/useToasts';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getErrorMessage } from 'utils/errors';
import ManfrediteProfileModal from '../Modal/ManfrediteProfileModal';
import { DEFAULT_HAS_LINKED_IN } from './ManfrediteProfileTopbar.constants';
import {
  StyledActions,
  StyledDropdown,
  StyledTopbarContainer,
  StyledTopbarNavTrigger,
  StyledTopbarOverlay,
  StyledTopbarRoot,
  StyledTopbarSection
} from './ManfrediteProfileTopbar.styled';
import { ManfrediteProfileTopbarProps } from './ManfrediteProfileTopbar.types';

const ManfrediteProfileTopbar: React.FC<ManfrediteProfileTopbarProps> = ({
  selectedViewType,
  setSelectedViewType,
  setIsNavOpened,
  isNavOpened,
  setIsActive,
  isDropdownOpen,
  setIsDropdownOpen,
  handleSaveSuccessCopy,
  saveAsPdfURL,
  url,
  includeMailContactInfo,
  includePhoneContactInfo,
  setIncludeMailContactInfo,
  setIncludePhoneContactInfo,
  isModalOpen,
  setIsModalOpen,
  publicProfileActive,
  hasLinkedIn = DEFAULT_HAS_LINKED_IN
}) => {
  const { t } = useI18n('profile-preview');
  const { locale } = useRouter();

  const { manfredite } = useManfredite();
  const { updateManfrediteFromModel } = useManfrediteUpdate();

  const { refreshUserData } = useContext(LoggedUserContext);

  function toggleNav(): void {
    setIsActive(isNavOpened ? 'is-active' : '');
    setIsNavOpened(isNavOpened ? true : false);
  }

  const [isPublicProfileActive, setIsPublicProfileActive] =
    useState(publicProfileActive);

  const { addSuccessToast, addErrorToast } = useToasts();

  async function handleProfileActiveChange(isActive: boolean): Promise<void> {
    if (!manfredite) return;

    await updateManfrediteFromModel(
      manfredite,
      {
        profilePreferences: {
          ...manfredite.profilePreferences,
          isPublic: isActive
        }
      },
      {
        onError: (ex) => {
          console.error(getErrorMessage(ex));

          addErrorToast(
            t('profile-preview.messages.error.active.desc', {
              error: getErrorMessage(ex)
            }),
            t('profile-preview.messages.error.active.title')
          );
        },
        onSuccess: () => {
          isActive
            ? addSuccessToast(
                <HtmlTrans
                  ns={'profile-preview'}
                  i18nKey={'profile-preview.actions.active.desc'}
                  components={{
                    privacy: (
                      <LinkText
                        target="_blank"
                        href={getAppUrl(
                          APP_URLS.portalV2PrivacyAndProfile,
                          locale
                        )}
                      />
                    )
                  }}
                ></HtmlTrans>,
                t('profile-preview.actions.active.title')
              )
            : addSuccessToast(
                <HtmlTrans
                  ns={'profile-preview'}
                  i18nKey={'profile-preview.actions.no-active.desc'}
                  components={{
                    privacy: (
                      <LinkText
                        target="_blank"
                        href={getAppUrl(
                          APP_URLS.portalV2PrivacyAndProfile,
                          locale
                        )}
                      />
                    )
                  }}
                ></HtmlTrans>,
                t('profile-preview.actions.no-active.title')
              );
        }
      }
    );
  }

  return (
    <>
      <ManfrediteProfileModal
        includeMailContactInfo={includeMailContactInfo}
        includePhoneContactInfo={includePhoneContactInfo}
        setIncludeMailContactInfo={setIncludeMailContactInfo}
        setIncludePhoneContactInfo={setIncludePhoneContactInfo}
        isOpen={isModalOpen}
        saveAsPdfURL={saveAsPdfURL}
        selectedViewType={selectedViewType}
        close={() => {
          setIsModalOpen(false);
        }}
      />

      <StyledTopbarRoot>
        {hasLinkedIn && <StyledTopbarOverlay />}
        <StyledTopbarContainer>
          <StyledTopbarSection>
            <StyledTopbarNavTrigger onClick={toggleNav}>
              <FontAwesomeIcon icon={faBars} />
            </StyledTopbarNavTrigger>

            <Switcher
              id="publicProfileActive"
              label={t('profile-preview.topbar.active-profile')}
              checked={isPublicProfileActive}
              variant="inverse"
              onChange={() => {
                refreshUserData({
                  publicProfileActive: !isPublicProfileActive
                });
                handleProfileActiveChange(!isPublicProfileActive ?? true);
                setIsPublicProfileActive((prevValue) => !prevValue);
              }}
            />
          </StyledTopbarSection>

          <StyledActions>
            <StyledTopbarSection>
              {isPublicProfileActive && (
                <Button
                  variant="tertiaryInverse"
                  onClick={() => {
                    handleSaveSuccessCopy(url);
                  }}
                  sizes="sm"
                >
                  <FontAwesomeIcon icon={faClone} />
                  <span>{t('profile-preview.topbar.copy-link')}</span>
                </Button>
              )}

              <ButtonLink
                variant="tertiaryInverse"
                sizes="sm"
                onClick={() => {
                  setIncludeMailContactInfo(true);
                  setIncludePhoneContactInfo(true);
                  setIsModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faCloudArrowDown} />
                <span>{t('profile-preview.topbar.pdf-download')}</span>
              </ButtonLink>
              <StyledDropdown>
                <Button
                  variant="secondaryInverse"
                  sizes="sm"
                  isTrigger
                  onClick={() => {
                    setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
                  }}
                  aria-haspopup="listbox"
                  aria-controls="cvView-dropdown"
                  aria-label="cvView"
                  aria-expanded={isDropdownOpen ? 'true' : 'false'}
                >
                  {selectedViewType === 'classic'
                    ? t('profile-preview.topbar.views.classic')
                    : t('profile-preview.topbar.views.timeline')}
                </Button>

                <Dropdown
                  options={[
                    {
                      label: t('profile-preview.topbar.views.timeline'),
                      value: 'timeline',
                      action: () => {
                        setIsDropdownOpen(false);
                        setSelectedViewType('timeline');
                      }
                    },
                    {
                      label: t('profile-preview.topbar.views.classic'),
                      value: 'classic',
                      action: () => {
                        setIsDropdownOpen(false);
                        setSelectedViewType('classic');
                      }
                    }
                  ]}
                  style={{ display: isDropdownOpen ? 'block' : 'none' }}
                />
              </StyledDropdown>
            </StyledTopbarSection>
          </StyledActions>
        </StyledTopbarContainer>
      </StyledTopbarRoot>
    </>
  );
};

export default ManfrediteProfileTopbar;
