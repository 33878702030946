import { useCopyToClipboard } from '@asgard/ui-common';
import ProfilePreviewComponent from 'components/profile-preview/ProfilePreviewComponent';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useI18n } from 'hooks/useI18n';
import { useToasts } from 'hooks/useToasts/useToasts';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import UIPublicProfileService from 'services/ui/publicprofile/publicprofile.service';
import { APP_URLS, getAppUrl, getPublicProfileUrl } from 'utils/app-urls';
import { config } from 'utils/config';
import { ManfrediteProfilePageProps } from './ManfrediteProfilePage.types';
import ManfrediteProfileNav from './Nav/ManfrediteProfileNav';
import { PROFILE_PREVIEW_NAV_V2 } from './Nav/ManfrediteProfileNav.constants';
import ManfrediteProfileTopbar from './Topbar/ManfrediteProfileTopbar';

const ManfrediteProfilePage: React.FC<ManfrediteProfilePageProps> = ({
  publicManfredite,
  view,
  publicProfileActive,
  showToolbar,
  slug
}) => {
  const router = useRouter();
  const [hasLinkedInQuery, setHasLinkedInQuery] = useState(
    Boolean(router.query.linkedin)
  );

  const { t } = useI18n('profile-preview');
  const { locale } = useRouter();
  const { isEnabled: isLinkedInEnabled } = useFeatureFlag(
    'talent_switch_linkedin_import'
  );

  const { addSuccessToast } = useToasts();
  const [, copyToClipboard] = useCopyToClipboard();

  const [isNavOpened, setIsNavOpened] = useState(true);
  const [selectedViewType, setSelectedViewType] =
    useState<ManfrediteProfilePageProps['view']>(view);

  const [isActive, setIsActive] = useState('');

  const [includeMailContactInfo, setIncludeMailContactInfo] = useState(false);
  const [includePhoneContactInfo, setIncludePhoneContactInfo] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const saveAsPdfUrlParams = new URLSearchParams();

  saveAsPdfUrlParams.set('print', 'true');

  if (includeMailContactInfo) {
    saveAsPdfUrlParams.set('includeMailContactInfo', 'true');
  }
  if (includePhoneContactInfo) {
    saveAsPdfUrlParams.set('includePhoneContactInfo', 'true');
  }

  const saveAsPdfURL = `${config.appUrl}/${getAppUrl(
    APP_URLS.profilePreview,
    locale
  )}/pdf?${saveAsPdfUrlParams}`;

  const url = `${getPublicProfileUrl({
    slug: slug || '',
    locale
  })}/${selectedViewType}`;

  const navSections = PROFILE_PREVIEW_NAV_V2(isLinkedInEnabled, locale);

  const uiPublicManfredite = UIPublicProfileService.getManfrediteUIData(
    publicManfredite,
    locale
  );

  useEffect(() => {
    function onPageLoad(): void {
      const queryParams = new URLSearchParams(window.location.search);
      const print = queryParams.get('print') === 'true';

      if (print && view === 'pdf') {
        window.print();
      }
    }

    setTimeout(onPageLoad, 1000);
  }, []);

  if (view === 'pdf') {
    return (
      <ProfilePreviewComponent
        publicManfredite={publicManfredite}
        setHasLinkedInQuery={setHasLinkedInQuery}
        type={view}
        isProfileHidden={!publicProfileActive}
      />
    );
  }

  function handleSaveSuccessCopy(url: string): void {
    copyToClipboard(url);

    addSuccessToast(t('profile-preview.actions.copy'));
  }

  return (
    <>
      {showToolbar && (
        <>
          {isNavOpened && (
            <ManfrediteProfileNav
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              includeMailContactInfo={includeMailContactInfo}
              includePhoneContactInfo={includePhoneContactInfo}
              setIncludeMailContactInfo={setIncludeMailContactInfo}
              setIncludePhoneContactInfo={setIncludePhoneContactInfo}
              publicManfredite={uiPublicManfredite}
              isNavOpened={isNavOpened}
              sections={navSections}
              isActive={isActive}
              setIsActive={setIsActive}
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
              selectedViewType={selectedViewType}
              setSelectedViewType={setSelectedViewType}
              handleSaveSuccessCopy={handleSaveSuccessCopy}
              saveAsPdfURL={saveAsPdfURL}
              url={url}
            />
          )}
          <ManfrediteProfileTopbar
            publicProfileActive={publicProfileActive}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            includeMailContactInfo={includeMailContactInfo}
            includePhoneContactInfo={includePhoneContactInfo}
            setIncludeMailContactInfo={setIncludeMailContactInfo}
            setIncludePhoneContactInfo={setIncludePhoneContactInfo}
            selectedViewType={selectedViewType}
            setSelectedViewType={setSelectedViewType}
            setIsNavOpened={setIsNavOpened}
            isNavOpened={isNavOpened}
            setIsActive={setIsActive}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            handleSaveSuccessCopy={handleSaveSuccessCopy}
            saveAsPdfURL={saveAsPdfURL}
            url={url}
            hasLinkedIn={hasLinkedInQuery}
          />
        </>
      )}
      <ProfilePreviewComponent
        publicManfredite={publicManfredite}
        setHasLinkedInQuery={setHasLinkedInQuery}
        type={selectedViewType}
        isProfileHidden={!publicProfileActive}
      />
    </>
  );
};

export default ManfrediteProfilePage;
