import styled from 'styled-components';
import { color, fontSize, fontWeight, screen, spacing } from 'theme';

export const StyledWrapper = styled.div``;

export const StyledIcon = styled.div`
  color: ${color('info_500')};
  font-size: ${fontSize('xl7')};
  text-align: center;
`;

export const StyledTitle = styled.h1`
  font-size: ${fontSize('lg')};
  font-weight: ${fontWeight('black')};
  color: ${color('midnight_500')};
  text-align: center;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl3')};
  }
`;

export const StyledText = styled.p`
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  color: ${color('midnight_800')};
  text-align: center;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('lg')};
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    flex-direction: row;

    > * {
      flex-basis: 50%;
    }
  }
`;

export const StyledChecks = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spacing('md')};
  margin-bottom: ${spacing('xl2')};
`;
